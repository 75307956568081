import { Directive, HostBinding, Input } from "@angular/core";

@Directive({
  selector: "[tableOptions]",
  standalone: true
})
export class TableOptionsDirective {
  @Input() width!: string;

  @HostBinding("style.width")
  get columnWidth(): string {
    return this.width;
  }

  @Input() align!: "center" | "end" | "justify" | "left" | "right" | "start";
  @HostBinding("style.text-align")
  get textlign(): string {
    return this.align;
  }

  @Input() dropdown?: string;
  @HostBinding("style.position")
  get dropdownPosition() {
    return this.dropdown === "" ? "relative" : "";
  }
}
