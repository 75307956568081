<div class="toast-wrapper" [ngClass]="toast.status" *ngFor="let toast of toastService.toasts; let i=index"
  (click)="toastService.clear(i)" [removeAfter]="hideAfter">
  <ng-container>
    <div class="taost-content">
      
      <!-- ICONA -->
      <!-- <div class="tost-icon">
        <span [ngClass]="toast.status"></span>
      </div> -->
      
      <!-- MESSAGGIO CUSTOM -->
      <!-- <div class="toast-status" *ngIf="toast.status !== 'NORMAL'">
        {{ 'toast.' + toast.status | translate }}
      </div> -->
      <div class="toast-message">
        <p>{{ toast.text }}</p>
      </div>
    </div>
  </ng-container>
</div>