import { Injectable } from "@angular/core";

import { environment as env } from "src/environments/environment";

export interface GeneralConfig {
    localTokenKey: string;
    loggerLevel: number;
    notAuthorizedPath: string;
    checkTokenValidityBeforeExpireMin: number;
    loginType: "local" | "saml-sso";
    loginUrl: string;
    ssoTokenKey: string;
    apiBaseUrl: string;
    baseHref: string;
    storeAccessToken: "cookies" | "sessionstorage";
}

@Injectable()
export class EnvironmentLoaderService {
    /**
     * SETTARE L'INDIRIZZO A CUI FARE LE CHIAMATE AI SERIVZI
     * Non può essere caricato direttamente dal file di configurazione in quanto l'istanza di questo serivice viene creata prima dell'inizializzazione dell'app
     */

    private envConfig!: GeneralConfig;
    private configPath = "";
    private commonPath = "";
    constructor() { }
    /**
    * Carica il file di configurazione dell'applicazione
    * Nel caso in cui un parametro sia presente sia nelle configurazioni comuni che in quelle dedicate,
    * il parametro presente nelle dedicate sovrasciverà il precedente parametro.
    */
    public async setAppConfig(): Promise<void> {
        /** Controlla l'indirizzo dell'uri per definire l'ambiente da caricare */
        const locatioURI: string = window.location.toString();

        /** In base all'uri recupera il path dell'ambiente corretto */
        this.setDirectoryBasedOnURI(locatioURI);

        /** Setta i dati di configurazione che verranno poi caricati al caricamento dell'applicazione */
        try {
            const commonConfig = await this.loadCommonConfig();
            const dedicatedConfig = await this.loadDedicatedConfig();
            this.envConfig = this.overwriteCommonWithDedicated(commonConfig, dedicatedConfig);
        } catch (err: unknown) {
            console.error((err as Error)?.message);
        }
    }
    /**
     * 
     * @async Carica i dati di configurazione comuni ai vari ambienti
     */
    private async loadCommonConfig(): Promise<GeneralConfig> {
        try {
            const commonConfigFile: Response = await fetch(this.commonPath);
            return await commonConfigFile.json() as GeneralConfig;
        } catch (err: unknown) {
            console.error((err as Error)?.message);
            return {} as GeneralConfig;
        }
    }
    /**
     * 
     * @async Carica i dati di configurazione dedicati
     */
    private async loadDedicatedConfig(): Promise<GeneralConfig> {
        try {
            const dedicatedConfigFile: Response = await fetch(this.configPath);
            return await dedicatedConfigFile.json() as GeneralConfig;
        } catch (err: unknown) {
            console.error((err as Error)?.message);
            return {} as GeneralConfig;
        }
    }
    /**
     * 
     * @returns Variabili d'ambiente caricate prima dell'inizializzazione dell'applicazione
     */
    public getEnvConfig(): GeneralConfig {
        return this.envConfig;
    }
    /**
     * 
     * In base alla URI, carica automaticamente la configurazione corretta
     *
     * @param location URI dell'applicazione
     */
    private setDirectoryBasedOnURI(location: string): void {
        if (location.startsWith("http://localhost") || location.startsWith("https://localhost") || location.match(/https:\/\/dev-.*\.alloy\.it/)) {
            this.configPath = env.configurationDevPath;
        } else if (location.match(/https:\/\/test-.*\.genurl\.it/)) {
            this.configPath = env.configurationStagingPath;
        } else {
            this.configPath = env.configurationProdPath;
        }
        this.commonPath = env.commonConfiguration;
    }

    /**
    *
    * @param commonConfig Configurazione comune ai vari ambienti
    * @param dedicatedConfig Configurazione dedicata ai vari ambienti
    * @returns Unione delle 2 configurazioni dove la dedicata sovrascrive i valori della comune se presenti
    */
    /* eslint-disable */
    private overwriteCommonWithDedicated(commonConfig: any, dedicatedConfig: any): GeneralConfig {
        /** Creazione di una copia della config comune */
        const merged = { ...commonConfig };

        for (const key of Object.keys(dedicatedConfig)) {
            /** Controllo che l'elemento corrente sia un un oggetto  */
            if (typeof dedicatedConfig[key] !== "number" && typeof dedicatedConfig[key] !== "boolean" && typeof dedicatedConfig[key] !== "string" && !Array.isArray(dedicatedConfig[key])) {
                /** Ricorsione in caso di oggetti annidati  */
                merged[key] = this.overwriteCommonWithDedicated(merged[key], dedicatedConfig[key]);
            } else {
                /**
                 * Inserisco il valore nel merged.
                 * Se il valore già esiste viene sovrascritto, altrimenti viene inserito
                 */
                merged[key] = dedicatedConfig[key];
            }
        }

        return merged;
    }
}
