import { Pipe, PipeTransform, inject } from "@angular/core";
import { TranslateService } from "../util/translate.service";

@Pipe({ name: "translate" })
export class TranslatePipe implements PipeTransform {
  i18n = inject(TranslateService);
  public transform(trKey: string, optValue?: { [k: string]: string | undefined | null }) {
    if (!this.i18n.translation) {
      return undefined;
    }
    if (!optValue) {
      return this.i18n.translation[trKey] ?? trKey;
    }

    let returnMsg = this.i18n.translation[trKey];
    for (const value in optValue) {
      returnMsg = returnMsg?.replaceAll(`{{${value}}}`, optValue[value] ?? "");
    }

    return returnMsg;
  }
}